import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { userActions } from '../../redux/actions/user_actions'
import useStyles from './style'

import { useTranslation } from 'react-i18next'

import LinearProgress from '@material-ui/core/LinearProgress'

import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { alertActions } from '../../redux/actions/alert_actions'

import { history } from 'src/helpers/history'
import { WEB_VERSION } from '../../constants/global_constants'

const AppUrl = process.env.PUBLIC_URL

const Login = ({ totp, alertReducer, clear, authenticationReducer }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [isRememberPasswordChecked, setisRememberPasswordChecked] =
    useState(true)
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    clear()
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    totp(email, password)
      .then((token) => {
        try {
          JSON.parse(token)
        } catch {
          console.log('token', token)
          history.push({
            pathname: '/multifactor',
            state: { email, password, qrBase64: token },
          })
        }
      })
      .catch((error) => {
        if (error.code === 5) {
          history.push({
            pathname: '/multifactor-code',
            state: { email, password },
          })
        }
      })
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        component={Paper}
        elevation={6}
        square
        className={classes.gridContainerStyle}
      >
        <div className={classes.paper}>
          <img
            alt={t('login.index.altImgLogo')}
            src={AppUrl + '/content/img/logo.png'}
            className={classes.logo}
          />
          <p className={classes.titleLogin}>{t('login.index.LoginTitle')}</p>
          {/* <Typography component="h1" variant="h5">
                        Iniciar sesión
                    </Typography> */}

          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('login.index.emailLabel')}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('login.index.passwordLabel')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
            {authenticationReducer.loginPending && <LinearProgress />}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRememberPasswordChecked}
                  color="primary"
                  onChange={() => setisRememberPasswordChecked((prev) => !prev)}
                />
              }
              label={t('login.index.rememberMeLabel')}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('login.index.login')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {' '}
                  {t('login.index.forgottenPassword')}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.silence.eco/">
                  {t('login.index.CopyrightText')}
                </Link>{' '}
                {new Date().getFullYear()}
                {`. - v${WEB_VERSION}`}
              </Typography>
            </Box>
          </form>

          {alertReducer.message && (
            <Snackbar
              open={alertReducer.message ? true : false}
              autoHideDuration={3000}
              onClose={handleCloseAlert}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleCloseAlert}
                severity={alertReducer.type}
              >
                {alertReducer.message}
              </MuiAlert>
            </Snackbar>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

function mapState(state) {
  const { alertReducer, authenticationReducer } = state
  return { alertReducer, authenticationReducer }
}

const actionCreators = {
  totp: userActions.totp,
  logout: userActions.logout,
  clear: alertActions.clear,
}

export default connect(mapState, actionCreators)(Login)
